<!--  -->
<template>
<div class=''>
  <div class="banner">
    <img :src="'http://tj-dasheng.com/api/sysFileInfo/public/preview?fileId='+hzdwcontent.fileId" alt="">
  </div>
  <ywly></ywly>
  <jjfa></jjfa>
  <hzdw></hzdw>
  <gywm></gywm>
  <lxwm></lxwm>
</div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import ywly from '@/components/home/ywly.vue'
import jjfa from '@/components/home/jjfa.vue'
import hzdw from '@/components/home/hzdw.vue'
import gywm from '@/components/home/gywm.vue'
import lxwm from '@/components/home/lxwm.vue'
export default {
//import引入的组件需要注入到对象中才能使用
components: {
  ywly,
  jjfa,
  hzdw,
  gywm,
  lxwm
},
data() {
//这里存放数据
return {
hzdwcontent:''
};
},
//监听属性 类似于data概念
computed: {},
//监控data中的数据变化
watch: {},
//方法集合
methods: {
},
//生命周期 - 创建完成（可以访问当前this实例）
created() {

},
//生命周期 - 挂载完成（可以访问DOM元素）
mounted() {

        this.$api
      .wxbannerlist({})
      .then((res) => {
        console.log(res)
        this.hzdwcontent = res.data[0]
       
      })
      .catch((err) => {
        console.log(err);
      });
},
beforeCreate() {}, //生命周期 - 创建之前
beforeMount() {}, //生命周期 - 挂载之前
beforeUpdate() {}, //生命周期 - 更新之前
updated() {}, //生命周期 - 更新之后
beforeDestroy() {}, //生命周期 - 销毁之前
destroyed() {}, //生命周期 - 销毁完成
activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='less' scoped>
  .banner{
    width: 100%;
    // height: 800px;
    // background: #000;
    img{
      width: 100%;
      // height: 100%;
    }
  }
</style>