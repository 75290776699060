<!--  -->
<template>
<div class='hzdw'>
     <div class="top">
      <div class="xian"></div>
      <div class="tit">
        <div>合作单位</div>
        <div>Cooperative unit</div>
      </div>
      <div class="xian"></div>
    </div>
     <div class="hzdwcontent">
      <div class="mm" v-for="(item,index) in hzdwcontent" :key='index' @click="link(item.linkUrl)">
        <div class="ss"><img :src="'http://tj-dasheng.com/api/sysFileInfo/public/preview?fileId='+item.logo" alt="" /></div>
        <div class="ss1">{{item.title}}</div>
      </div>
    </div>
</div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
//import引入的组件需要注入到对象中才能使用
components: {},
data() {
//这里存放数据
return {
 hzdwcontent:[]
};
},
//监听属性 类似于data概念
computed: {},
//监控data中的数据变化
watch: {},
//方法集合
methods: {
link(url){
       window.location.href = url;
    }
},
//生命周期 - 创建完成（可以访问当前this实例）
created() {

},
//生命周期 - 挂载完成（可以访问DOM元素）
mounted() {
 this.$api
      .cooperationUnitapiList({})
      .then((res) => {
        this.hzdwcontent = res.data
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
},
beforeCreate() {}, //生命周期 - 创建之前
beforeMount() {}, //生命周期 - 挂载之前
beforeUpdate() {}, //生命周期 - 更新之前
updated() {}, //生命周期 - 更新之后
beforeDestroy() {}, //生命周期 - 销毁之前
destroyed() {}, //生命周期 - 销毁完成
activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='less' scoped>
.hzdw{
    width: 1200px;
    min-height: 472px;
    margin: auto;
}
.top {
  width: 367px;
  padding-top: 164px;
  margin: auto;
  margin-bottom: 52px;
  .xian {
    width: 96px;
    height: 2px;
    background: #e5e5e5;
    margin-top: 17px;
  }

  display: flex;
  justify-content: space-between;
  .tit {
    padding: 0 30px;
    text-align: center;
  }
  .tit div:nth-child(1) {
    font-size: 26px;
    color: #333333;
    letter-spacing: 2.6px;
    text-align: center;
    line-height: 36px;
  }
  .tit div:nth-child(2) {
    font-family: ArialMT;
    font-size: 14px;
    color: #cccccc;
    letter-spacing: 0.8px;
    text-align: center;
    line-height: 20px;
  }
}
.ss{
    width: 246px;
  height: 184px;
  margin: auto;
     img {
    width: 100%;
    height: 144px;
     margin: 25px 0;
    border: 1px solid #EFEFEF;
  }
}
.ss1{
    font-size: 16px;
color: #333333;
letter-spacing: 0.91px;
text-align: center;
text-align: center;
margin-top: 14px;
}
.mm{
    width: 300px;
    //  margin: 43px 0;
    cursor: pointer;
}
.hzdwcontent{
    display: flex;
    // justify-content: space-between;
    flex-wrap: wrap;
    width: 1200px;
     padding-bottom: 68px;
}
.mm:hover{
      transform: translateY(-5px);
    transition-duration: 0.5s;
    box-shadow: 0px 7px 7px -7px #5e5e5e;
}
</style>