<!--  -->
<template>
  <div class="jjfa">
    <div class="top">
      <div class="xian"></div>
      <div class="tit">
        <div>汽车行业解决方案</div>
        <div>Automotive industry solutions</div>
      </div>
      <div class="xian"></div>
    </div>

    <div class="carcontent">
      <div class="left">
        <div class="mytit">{{fadataList.title}}</div>
        <div class="mytit1">
           {{fadataList.introduction}}
         </div>
        <div class="an" @click="more()">more ></div>
      </div>
      <div class="right"><img :src="'http://tj-dasheng.com/api/sysFileInfo/public/preview?fileId='+fadataList.fileId" alt=""></div>
    </div>

    <div class="si">
      <div class="sidiv" v-for="(item,index) in datalist" :key="index">
        <div class="sidivt">{{item.number}} <div class="jia">+</div></div>
       
        <div class="xz">{{item.name}}</div>
      </div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      datalist:[],
      fadataList:[]
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    more(){
      this.$router.push('/jjfa')
    }
 
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
       this.$api
      .wxhometongji({})
      .then((res) => {
        console.log(res)
        this.datalist = res.data;
      })
      .catch((err) => {
        console.log(err);
      });

        this.$api
      .wxcarIndustryPlanpage({})
      .then((res) => {
        // this.hzdwcontent = res.data
        this.fadataList = res.data.rows[0]
        console.log( this.fadataList);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
.top {
  width: 487px;
  padding-top: 66px;
  margin: auto;
  margin-bottom: 52px;
  .xian {
    width: 96px;
    height: 2px;
    background: #e5e5e5;
    margin-top: 17px;
  }

  display: flex;
  justify-content: space-between;
  .tit {
    padding: 0 30px;
    text-align: center;
  }
  .tit div:nth-child(1) {
    font-size: 26px;
    color: #333333;
    letter-spacing: 2.6px;
    text-align: center;
    line-height: 36px;
  }
  .tit div:nth-child(2) {
    font-family: ArialMT;
    font-size: 14px;
    color: #cccccc;
    letter-spacing: 0.8px;
    text-align: center;
    line-height: 20px;
  }
}
.jjfa {
  background: #f8f8f8;
  height: 656px;
  position: relative;
}
.carcontent {
  width: 1200px;
  margin: auto;
}

.mytit {
  margin: 30px 0 16px;
  font-size: 20px;
  color: #333333;
  letter-spacing: 1.43px;
  line-height: 32px;
}
.mytit1 {
  font-size: 14px;
  color: #999999;
  letter-spacing: 1px;
  line-height: 28px;
  margin-top: 30px;
  margin-bottom: 30px;
}
.left {
  width: 656px;
}
.an {
  background: #237bff;
  width: 150px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  color: #ffffff;
  letter-spacing: 1px;
  cursor: pointer;
}
.right {
  width: 436px;
  height: 238px;
   img{
    width:100%;
    height:100%;
  }
}
.carcontent {
  display: flex;
  justify-content: space-between;
}
.si {
  display: flex;
  justify-content: space-between;
  position: absolute;
  left: 50%;
  margin-left: -600px;
  bottom: -100px;
  width: 1200px;
  background: #fff;
  box-shadow: 0 2px 20px 0 rgba(0,0,0,0.06);
  .sidiv {
    padding: 62px 89px 50px;

    .xz {
      font-size: 14px;
      color: #999999;
      letter-spacing: 1px;
      line-height: 20px;
      margin-top: 8px;
      text-align: center;
    }
    .sidivt {
      position: relative;
      font-size: 48px;
      color: #237bff;
      letter-spacing: 3.42px;
      line-height: 52px;
      .jia {
          position: absolute;
          right: -20px;
          top: -30px;
          color:  #237BFF;
          font-size: 30px;
      }
    }
  }
}
</style>