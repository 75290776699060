<!--  -->
<template>
<div class='zz'>
     <div class="top">
      <div class="xian"></div>
      <div class="tit">
        <div>联系我们</div>
        <div>contact us</div>
      </div>
      <div class="xian"></div>
    </div>

    <div class="bot">
        <div>
            <img src="../../assets/yx.png" alt="">
            邮箱：{{item.email}}
        </div>
         <div>
            <img src="../../assets/dh.png" alt="">
           电话：{{item.phone}}
        </div>
         <div>
            <img src="../../assets/dz.png" alt="">
            地址：{{item.address}}
        </div>
    </div>
</div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
//import引入的组件需要注入到对象中才能使用
components: {},
data() {
//这里存放数据
return {
  item:''
};
},
//监听属性 类似于data概念
computed: {},
//监控data中的数据变化
watch: {},
//方法集合
methods: {

},
//生命周期 - 创建完成（可以访问当前this实例）
created() {

},
//生命周期 - 挂载完成（可以访问DOM元素）
mounted() {
  this.$api
      .wxcontactdetail({})
      .then((res) => {
        // this.hzdwcontent = res.data
        this.item = res.data
      })
      .catch((err) => {
        console.log(err);
      });
},
beforeCreate() {}, //生命周期 - 创建之前
beforeMount() {}, //生命周期 - 挂载之前
beforeUpdate() {}, //生命周期 - 更新之前
updated() {}, //生命周期 - 更新之后
beforeDestroy() {}, //生命周期 - 销毁之前
destroyed() {}, //生命周期 - 销毁完成
activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='less' scoped>
.top {
  width: 367px;
  padding-top: 66px;
  margin: auto;
  margin-bottom: 52px;
  .xian {
    width: 96px;
    height: 2px;
    background: #e5e5e5;
    margin-top: 17px;
  }

  display: flex;
  justify-content: space-between;
  .tit {
    padding: 0 30px;
    text-align: center;
  }
  .tit div:nth-child(1) {
    font-size: 26px;
    color: #333333;
    letter-spacing: 2.6px;
    text-align: center;
    line-height: 36px;
  }
  .tit div:nth-child(2) {
    font-family: ArialMT;
    font-size: 14px;
    color: #cccccc;
    letter-spacing: 0.8px;
    text-align: center;
    line-height: 20px;
  }
}
.bot{
    width: 310px;
    margin: auto;
    div{
        display: flex;
        font-size: 14px;
color: #333333;
letter-spacing: 0.8px;
margin-bottom: 17px;
        img{
            width: 17px;
            height: 13px;
            display: block;
            margin-top: 4px;
            margin-right: 13px;
        }
    }
}
.zz{
    min-height: 370px;
}
</style>