<!--  -->
<template>
  <div class="">
    <div class="top">
      <div class="xian"></div>
      <div class="tit">
        <div>业务领域</div>
        <div>Business area</div>
      </div>
      <div class="xian"></div>
    </div>

    <div class="ywly">
      <div class="bodyDiv" v-for="(item, index) in dataList" :key="index">
        <img :src="'http://tj-dasheng.com/api/sysFileInfo/public/preview?fileId='+item.icon" alt="" />
        <div class="xiaoTit">{{ item.title }}</div>
      </div>
    </div>

    <div class="top">
      <div class="xian"></div>
      <div class="tit">
        <div>经典案例</div>
        <div>Preponderance</div>
      </div>
      <div class="xian"></div>
    </div>

    <swiper
      style="margin-top: 52px; margin-bottom: 52px"
      :options="swiperOption"
      ref="mySwiper"
    >
      <swiper-slide
        class="swiper-slide"
        v-for="(item, index) in collocateContentDtos"
        :key="index"
      >
        <img :src="item.image" alt="" />
        <!--          @click.native="goJump(item)" 每一个图片的点击事件-->
        <div class="bs-swiper-bottom">
          <div>{{ item.desc }}</div>
          <div>{{ item.date }}</div>
        </div>
      </swiper-slide>
      <div class="swiper-button-prev" slot="button-prev"></div>
      <div class="swiper-button-next" slot="button-next"></div>
    </swiper>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  data() {
    return {
      dataList: [],
      collocateContentDtos: [
       
      ],

      swiperOption: {
        slidesPerView: 3, //一行显示4个
        spaceBetween: 10, //间隔30
        freeMode: true,
        speed: 1000, //滑动速度
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  mounted() {
    console.log("Current Swiper instance object", this.swiper);
    this.$api
      .businessAreaapiList({})
      .then((res) => {
        // this.hzdwcontent = res.data
        this.dataList = res.data;
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });

      this.$api
        .wxclassicCasepage({ })
        .then((res) => {
          // this.hzdwcontent = res.data.rows;
          var list = [];
          for(var i = 0; i<res.data.rows.length; i++){
            list.push({
                image: "http://tj-dasheng.com/api/sysFileInfo/public/preview?fileId="+res.data.rows[i].fileId,
                desc: res.data.rows[i].title,
                date: res.data.rows[i].introduction,
            })
          }
          this.collocateContentDtos = list
          console.log(list);
        })
        .catch((err) => {
          console.log(err);
        });
  },
};
</script>
<style lang='less' scoped>
.swiper-container {
  width: 1200px;
  padding: 0 5px;
  margin: auto;
}
.swiper-slide {
  width: 400px;
  // height: 400px;
  position: relative;
  img {
    width: 276px;
    height: 176px;
    display: block;
    margin: auto;
    margin-top: 38px;
  }
}
.bs-swiper-bottom {
  text-align: center;
}
.bs-swiper-bottom div:nth-child(1) {
  margin: 38px 0 16px;
  font-size: 20px;
  color: #333333;
  letter-spacing: 1.14px;
  text-align: center;
  line-height: 28px;
  font-weight: bold;
}
.bs-swiper-bottom div:nth-child(2) {
  font-size: 14px;
  color: #999999;
  letter-spacing: 1px;
  text-align: center;
}
.swiper-slide:nth-child(2n) {
  border-left: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
}

.top {
  width: 367px;
  padding-top: 66px;
  margin: auto;
  .xian {
    width: 96px;
    height: 2px;
    background: #e5e5e5;
    margin-top: 17px;
  }

  display: flex;
  justify-content: space-between;
  .tit {
    padding: 0 30px;
    text-align: center;
  }
  .tit div:nth-child(1) {
    font-size: 26px;
    color: #333333;
    letter-spacing: 2.6px;
    text-align: center;
    line-height: 36px;
  }
  .tit div:nth-child(2) {
    font-family: ArialMT;
    font-size: 14px;
    color: #cccccc;
    letter-spacing: 0.8px;
    text-align: center;
    line-height: 20px;
  }
}

.ywly {
  width: 1054px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  margin-top: 53px;
  .xiaoTit {
    font-size: 20px;
    color: #333333;
    letter-spacing: 1.14px;
    text-align: center;
    line-height: 32px;
    margin-top: 16px;
    text-align: center;
  }
  .bodyDiv {
    width: 170px;
    img {
      width: 134px;
      height: 134px;
      margin: auto;
      display: block;
    }
  }
}
</style>